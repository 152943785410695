// General
import { ReactElement } from 'react';
import classNames from 'classnames';

// Styles
import './page-section-title.style.scss';

// Main component Interface
export interface CartReviewSectionHeaderProps {
    title: string;
    subTitle?: string;
    className?: string;
}

// Main component
const PageSectionTitle = ({ title, subTitle, className }: CartReviewSectionHeaderProps): ReactElement => {
    const containerClasses = classNames('page-section-title', className);

    return (
        <div className={containerClasses}>
            <div className="page-section-title__title-container">
                <div className="blue-half-pill" />
                <div className="title">{title}</div>
            </div>
            {subTitle && <div className="page-section-title__subtitle-container">{subTitle}</div>}
        </div>
    );
};

export default PageSectionTitle;
