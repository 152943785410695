// General
import classNames from 'classnames';
import { ReactElement } from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

// Ui-kit
import UserCircleIcon from 'ui-kit/icons/user-circle';

// Schemas
import { formatPrice } from 'schema/price.schema';

// Styles
import './membership-available-plan-details.style.scss';
// Interface and Types
import {
    MembershipAvailablePlanDetailsProps,
    MembershipAvailablePlanFeatureItemProps,
    MembershipAvailablePlanPriceAccumulatedProps,
    MembershipAvailablePlanPriceProps
} from './membership-available-plan-details.types';

// Constants
const COMP_TRANSLATION = 'components.membership.availablePlan.details';

// Sub-component - Plan Name
const PlanName = ({ children }: { children: string }): ReactElement => {
    return <div className="membership-available-plan-details__name">{children}</div>;
};

// Sub-component - Available Members
const AvailableMembers = ({ total }: { total: number }): ReactElement => {
    const { t } = useTranslation();
    let label = t(COMP_TRANSLATION + '.members');

    if (total < 2) {
        label = label.slice(0, -1);
    }

    return (
        <div className="membership-available-plan-details__available-members">
            {total > 1 && <span>{t('components.membership.availablePlan.details.carePlusMembers')} </span>}
            <span className="total">{total}</span>
            {label}
            <div className="members-icon-list">
                {[...Array(total)].map((x, i) => (
                    <UserCircleIcon key={`membership-available-member-${i}`} />
                ))}
            </div>
        </div>
    );
};

// Sub-component - Feature Item
const FeatureItem = ({
    name,
    total,
    monthlyFrequency,
    isPayAsYouGo
}: MembershipAvailablePlanFeatureItemProps): ReactElement => {
    const { t } = useTranslation();
    const featureText = t(`${COMP_TRANSLATION}.features.${name}`, { total });
    let monthlyFrequencyText = t(`${COMP_TRANSLATION}.monthlyFrequency.perMonth`);

    if (monthlyFrequency && monthlyFrequency > 1) {
        monthlyFrequencyText = t(`${COMP_TRANSLATION}.monthlyFrequency.every`);
    }

    return (
        <>
            {name !== 'onDemand' ? (
                <div className="membership-available-plan-details__feature-item">
                    {!isPayAsYouGo && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(featureText, {
                                    allowedTags: ['strong']
                                })
                            }}
                        />
                    )}

                    {!isPayAsYouGo && monthlyFrequency && (
                        <div className="monthlyFrequency">{monthlyFrequencyText}</div>
                    )}
                </div>
            ) : (
                <>
                    <div className="membership-available-plan-details__feature-item">
                        {isPayAsYouGo && <div className="monthlyFrequency">{t(`${COMP_TRANSLATION}.onDemand1`)}</div>}
                    </div>
                    <div className="membership-available-plan-details__feature-item">
                        {isPayAsYouGo && <div className="monthlyFrequency">{t(`${COMP_TRANSLATION}.onDemand2`)}</div>}
                    </div>
                </>
            )}
        </>
    );
};

// Sub-component - Price
const Price = ({
    total,
    priceToJoin = false,
    isPayAsYouGo = false
}: MembershipAvailablePlanPriceProps): ReactElement => {
    const { t } = useTranslation();
    const containerClasses = classNames('membership-available-plan-details__price', { 'price-to-join': priceToJoin });
    const priceText = t(`${COMP_TRANSLATION}.price.${isPayAsYouGo ? 'perVisit' : priceToJoin ? 'toJoin' : 'monthly'}`, {
        total: formatPrice(total)
    });
    return (
        <div
            className={containerClasses}
            dangerouslySetInnerHTML={{
                __html: sanitizeHtml(priceText, {
                    allowedTags: ['strong', 'small']
                })
            }}
        />
    );
};

// Sub-component - Price Accumulated
const PriceAccumulated = ({
    total,
    monthlyFrequency,
    showBilled = false
}: MembershipAvailablePlanPriceAccumulatedProps): ReactElement => {
    const { t } = useTranslation();
    const priceText = t(`${COMP_TRANSLATION}.priceAccumulated`, { total: formatPrice(total), monthlyFrequency });
    const containerClasses = classNames('membership-available-plan-details__price-accumulated', {
        'show-billed': showBilled
    });

    return (
        <div
            className={containerClasses}
            dangerouslySetInnerHTML={{
                __html: sanitizeHtml(priceText, {
                    allowedTags: ['span']
                })
            }}
        />
    );
};

// Sub-component - Join Fee
const JoinFee = ({ total }: { total: string | number }): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="membership-available-plan-details__join-fee">{t(`${COMP_TRANSLATION}.joinFee`, { total })}</div>
    );
};

// Main component
const MembershipAvailablePlanDetails = ({
    children,
    className,
    onClick
}: MembershipAvailablePlanDetailsProps): ReactElement => {
    const containerClasses = classNames('membership-available-plan-details', className);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={containerClasses} onClick={onClick}>
            {children}
        </div>
    );
};

MembershipAvailablePlanDetails.PlanName = PlanName;
MembershipAvailablePlanDetails.AvailableMembers = AvailableMembers;
MembershipAvailablePlanDetails.FeatureItem = FeatureItem;
MembershipAvailablePlanDetails.Price = Price;
MembershipAvailablePlanDetails.PriceAccumulated = PriceAccumulated;
MembershipAvailablePlanDetails.JoinFee = JoinFee;

export default MembershipAvailablePlanDetails;
