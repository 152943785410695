// General
import dr from 'assets/icons/dr.svg';
import birdBlue from 'assets/icons/icon-bird-fullsize-blue.svg';
import drBlue from 'assets/icons/icon-dr-fullsize-blue.svg';
import rxBlue from 'assets/icons/icon-rx-fullsize-blue.svg';
import teleBlue from 'assets/icons/icon-tele-fullsize-blue.svg';
import visitsBlue from 'assets/icons/icon-visits-fullsize-blue.svg';
import pillBottle from 'assets/icons/pillBottle.svg';
import planeBlue from 'assets/icons/plane-blue.svg';
import plane from 'assets/icons/plane.svg';
import classNames from 'classnames';

// Ui-kit: icons
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import Link from 'ui-kit/link/link';

// Styles
import './quick-link.style.scss';

const mapIconSrc = (icon: QuickLinkProps['icon']) => {
    switch (icon) {
        case 'pill-bottle':
            return pillBottle;
        case 'dr':
            return dr;
        case 'plane':
            return plane;
        case 'plane-blue':
            return planeBlue;
        case 'bird-fullsize-blue':
            return birdBlue;
        case 'tele-fullsize-blue':
            return teleBlue;
        case 'visits-fullsize-blue':
            return visitsBlue;
        case 'rx-fullsize-blue':
            return rxBlue;
        case 'dr-fullsize-blue':
            return drBlue;
    }
};

type IconType =
    | 'pill-bottle'
    | 'dr'
    | 'plane'
    | 'plane-blue'
    | 'bird-fullsize-blue'
    | 'tele-fullsize-blue'
    | 'rx-fullsize-blue'
    | 'dr-fullsize-blue'
    | 'visits-fullsize-blue';

export interface QuickLinkProps {
    icon: IconType;
    iconVariant?: 'dark' | 'light' | 'alert' | 'info' | 'warning';
    type?: 'link' | 'button';
    title: string;
    bodyText?: string;
    open?: boolean;
    hideChevron?: boolean;
    alignCenter?: boolean;
    to?: string;
    dataGALocation: string;
    className?: string;
    onClick?: (() => void) | undefined;
    onKeyDown?: (() => void) | undefined;
}
type isLink<T> = T extends { type: 'link' } ? { to: string } : {};

export type DinamycQuickLinkProps = QuickLinkProps & isLink<QuickLinkProps>;

const QuickLink: React.FC<DinamycQuickLinkProps> = ({
    icon,
    title,
    bodyText,
    to,
    dataGALocation,
    className,
    onClick,
    type,
    hideChevron = false,
    alignCenter = false,
    onKeyDown = onClick
}) => {
    const containerClasses = classNames('quick-link-dashboard', className);
    const imageClasses = classNames('quick-link-dashboard__content-container__icon', {
        'full-size': icon.includes('fullsize')
    });

    if (type === 'link') {
        return (
            <Link
                to={to as string}
                label={''}
                dataGALocation={dataGALocation}
                onClick={onClick}
                className={containerClasses}
                isQuickLink={true}
            >
                <div className="quick-link-dashboard__main-container">
                    <div
                        className={`quick-link-dashboard__content-container ${
                            alignCenter && 'quick-link-dashboard__align-center'
                        }`}
                    >
                        <div className={imageClasses}>
                            <img src={mapIconSrc(icon)} alt={`Membership Dashboard ${icon}`} />
                        </div>
                        <h3 className="quick-link-dashboard__content-container__title">{title}</h3>
                        {bodyText && <p className="quick-link-dashboard__content-container__description">{bodyText}</p>}
                    </div>
                    {!hideChevron && (
                        <div className="quick-link-dashboard__icon-container">
                            <ChevronIcon direction="right" />
                        </div>
                    )}
                </div>
            </Link>
        );
    } else {
        return (
            <div className={containerClasses}>
                <div role="button" tabIndex={0} onKeyDown={onKeyDown} onClick={onClick}>
                    <div className="quick-link-dashboard__main-container">
                        <div
                            className={`quick-link-dashboard__content-container ${
                                alignCenter && 'quick-link-dashboard__align-center'
                            }`}
                        >
                            <div className={imageClasses}>
                                <img src={mapIconSrc(icon)} alt={`Membership Dashboard ${icon}`} />
                            </div>
                            <h3 className="quick-link-dashboard__content-container__title">{title}</h3>
                            {bodyText && (
                                <p className="quick-link-dashboard__content-container__description">{bodyText}</p>
                            )}
                        </div>
                        {!hideChevron && (
                            <div className="quick-link-dashboard__icon-container">
                                <ChevronIcon direction="right" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};
export default QuickLink;
