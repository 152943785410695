import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// ui-kit
import Button from 'ui-kit/button/button';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';

// Components
import { BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// Reducers
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

// Types
import { HTMLInputChange } from 'types/globals';
import { PaymentCardProps } from 'types/payment';

// Utils
import { ellipsify, lowercaseAndCapitalize } from 'util/cart';
import { noop } from 'util/function';
import { getPhoneNumber } from 'util/globalVariables';
import { getCreditCardEnding } from 'util/payments';

// Styles
import './payment-card.style.scss';

const PaymentCard: React.FC<PaymentCardProps> = (props) => {
    const {
        cardHolder,
        cardType,
        endingDigits,
        expiryMonth,
        expiryYear,
        isDefaultCard = false,
        isSelectCardRadioInputChecked = false,
        onRemoveCardLinkClick = noop,
        onSelectCardRadioInputChange = noop,
        onSetDefaultClick = noop,
        removeCardLinkClassName,
        setAsDefaultCardLinkClassName,
        showLabel = true,
        showRemoveCardLink = false,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        isInnerWidthViewport,
        isBorderlessCard = false,
        isMembershipSettings = false,
        isDefaultMembershipCard = false,
        isBusy = false
    } = props;

    const { t } = useTranslation();

    const handleSelectCardRadioInputClick = React.useCallback<NonNullable<HTMLInputChange>>(
        (event) => {
            onSelectCardRadioInputChange(event);
            event.currentTarget.blur();
        },
        [onSelectCardRadioInputChange]
    );

    const formattedExpiryMonth = parseInt(expiryMonth) < 10 ? `0${expiryMonth}` : expiryMonth;

    const showActionLinkSection = showRemoveCardLink || showSetDefaultLink;
    const showActionLinkSeparator = showRemoveCardLink && showSetDefaultLink;
    const dispatch = useDispatch();

    const customClass = classNames({
        'payment-card h-100': true,
        'border rounded rounded-2': !isBorderlessCard,
        'borderless-card': isBorderlessCard
    });

    const checkCardMembership = () => {
        if (isDefaultMembershipCard) {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'xl',
                    isModalCentered: true,
                    headerContent: (
                        <BirdiModalHeaderWithBackgroundColor
                            headerText={t('modals.removeDefaultMembershipPayment.title')}
                            type="danger"
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('modals.removeDefaultMembershipPayment.body')}
                            note={t('modals.removeDefaultMembershipPayment.note', {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.removeDefaultMembershipPayment.cta'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            }
                        }
                    ],
                    className: 'remove-default-membership-card'
                })
            );
        } else {
            onRemoveCardLinkClick();
        }
    };

    return (
        <Col className="payment-card__wrapper flex-fill">
            <div className={customClass}>
                <Row className="d-flex">
                    {showSelectCardRadioInput && (
                        <Col xs={2}>
                            <section className="payment-card--default-payment">
                                <label className="default-payment text-slate mb-0 d-flex">
                                    <input
                                        checked={isSelectCardRadioInputChecked}
                                        data-input-type="profile-payment-option"
                                        name="payment-option"
                                        onChange={handleSelectCardRadioInputClick}
                                        type="radio"
                                    />
                                </label>
                            </section>
                        </Col>
                    )}
                    <Col xs={showSelectCardRadioInput ? 10 : 12}>
                        <Row className="payment-card--content h-100">
                            <Col xs={12} className={'align-self-start'}>
                                <div className="d-flex justify-content-between">
                                    {showLabel && (
                                        <p className="payment-card__type align-self-start">
                                            {t(
                                                `pages.profile.payment.${
                                                    isDefaultCard ? 'primaryPayment' : 'secondaryPayment'
                                                }`,
                                                {
                                                    isMembership:
                                                        isMembershipSettings && isDefaultCard
                                                            ? t('pages.profile.payment.membershipPayment')
                                                            : ''
                                                }
                                            )}
                                        </p>
                                    )}
                                </div>
                                <div className="d-flex flex-row payment-card__info">
                                    <div>
                                        <div className="h6 hero-eyebrow-text">
                                            <span className="text-capitalize d-inline-block card-type">
                                                {`${
                                                    isInnerWidthViewport?.current
                                                        ? ellipsify(lowercaseAndCapitalize(cardType))
                                                        : cardType
                                                } ${getCreditCardEnding(endingDigits, '····')}`}
                                            </span>
                                        </div>
                                        <div className="h6 hero-eyebrow-text">{cardHolder}</div>
                                        <div className="h6 hero-eyebrow-text">
                                            {t('pages.profile.payment.expiry', {
                                                month: formattedExpiryMonth,
                                                year: expiryYear
                                            })}
                                        </div>
                                    </div>
                                    <CreditCardIcon
                                        className="payment-card__credit-card-icon"
                                        variant={cardType.toUpperCase()}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} className="align-self-end">
                                {isBusy ? (
                                    <SpinnerInline />
                                ) : (
                                    showActionLinkSection && (
                                        <section className="payment-card--default-payment justify-content-start mt-4 d-flex">
                                            {showRemoveCardLink && (
                                                <Button
                                                    className={classNames(
                                                        'px-0 pt-0 no-min-width',
                                                        removeCardLinkClassName
                                                    )}
                                                    label={t('shipping.removeAddress')}
                                                    onClick={checkCardMembership}
                                                    type="button"
                                                    variant="text"
                                                />
                                            )}
                                            {showActionLinkSeparator && <span className="divider">|</span>}
                                            {showSetDefaultLink && isDefaultCard && (
                                                <span className="strong px-0 pt-0 no-min-width">
                                                    {t('pages.profile.payment.labels.defaultPayment')}
                                                </span>
                                            )}
                                            {showSetDefaultLink && !isDefaultCard && (
                                                <Button
                                                    className={classNames(
                                                        'px-0 pt-0 no-min-width',
                                                        'set-as-default--link',
                                                        setAsDefaultCardLinkClassName
                                                    )}
                                                    dataGAFormName="address-card"
                                                    label={t('pages.profile.payment.labels.setAsDefault')}
                                                    onClick={onSetDefaultClick}
                                                    type="button"
                                                    variant="text"
                                                />
                                            )}
                                        </section>
                                    )
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default PaymentCard;
