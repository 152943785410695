// General
import { ReactElement } from 'react';
import classNames from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import PlanDetails from '../details';

// Ui-kit
import BadgeStatus from 'ui-kit/bagde/badge-status';
import BorderedContainer from 'ui-kit/bordered-container/bordered-container';

// Interface and Types
import {
    MembershipAvailablePlanFeatureItemProps,
    MembershipAvailablePlanPriceAccumulatedProps
} from '../details/membership-available-plan-details.types';

// Utils
import { convertToTitleCase } from 'util/string';

// Styles
import './membership-available-plan-card.style.scss';

// Constants
const COMP_TRANSLATION = 'components.membership.availablePlan.card';

// Interface and Types
export interface MembershipAvailablePlanCardProps {
    membershipPlanId: number;
    planName: string;
    availableMembers?: number;
    features: MembershipAvailablePlanFeatureItemProps[];
    price: string | number;
    priceToJoin?: boolean;
    savePercentage?: string | number | null;
    priceAccumulated?: MembershipAvailablePlanPriceAccumulatedProps;
    joinFee?: string | number;
    className?: string;
    isSelected?: boolean;
    isPayAsYouGo?: boolean;
    onChange?: () => void;
}

const MembershipAvailablePlanCard = ({
    planName,
    availableMembers,
    features,
    price,
    priceToJoin = false,
    savePercentage,
    priceAccumulated,
    joinFee,
    isSelected = false,
    isPayAsYouGo,
    className,
    onChange
}: MembershipAvailablePlanCardProps): ReactElement => {
    const { t } = useTranslation();
    const containerClasses = classNames('membership-available-plan-card', className, { selected: isSelected });

    return (
        <BorderedContainer className={containerClasses}>
            <PlanDetails className="membership-available-plan-card__main-container" onClick={onChange}>
                <>
                    {onChange && (
                        <div className="membership-available-plan-card__checkbox-container">
                            <input
                                checked={isSelected}
                                data-input-type="profile-membership-available-plan"
                                name="membership-available-plan"
                                onChange={onChange}
                                type="radio"
                            />
                        </div>
                    )}
                    <div className="membership-available-plan-card__card-container">
                        <div className="membership-available-plan-card__details-container">
                            <PlanDetails.PlanName>{convertToTitleCase(planName)}</PlanDetails.PlanName>
                            {!isPayAsYouGo && availableMembers && (
                                <PlanDetails.AvailableMembers total={availableMembers} />
                            )}
                            {!isPayAsYouGo &&
                                features &&
                                features.map((feature, index) => (
                                    <PlanDetails.FeatureItem
                                        key={`membership-available-plan-card-feature-${index}`}
                                        name={feature.name}
                                        total={feature.total}
                                        monthlyFrequency={feature.monthlyFrequency}
                                        isPayAsYouGo={isPayAsYouGo}
                                    />
                                ))}
                            {isPayAsYouGo && features && (
                                <PlanDetails.FeatureItem
                                    key={`membership-available-plan-card-feature-on-demand`}
                                    name={'onDemand'}
                                    total={0}
                                    monthlyFrequency={0}
                                    isPayAsYouGo={isPayAsYouGo}
                                />
                            )}
                        </div>
                        <div className="membership-available-plan-card__price-container">
                            <div className="main-price">
                                <PlanDetails.Price
                                    total={isPayAsYouGo ? joinFee : price}
                                    priceToJoin={priceToJoin}
                                    isPayAsYouGo={isPayAsYouGo}
                                />
                                {savePercentage && (
                                    <BadgeStatus
                                        label={t(COMP_TRANSLATION + '.savePercentage', { total: savePercentage })}
                                    />
                                )}
                            </div>
                            {priceAccumulated && (
                                <PlanDetails.PriceAccumulated
                                    total={priceAccumulated.total}
                                    monthlyFrequency={priceAccumulated.monthlyFrequency}
                                    showBilled={priceAccumulated.showBilled}
                                />
                            )}
                            {!isPayAsYouGo && joinFee && <PlanDetails.JoinFee total={joinFee} />}
                        </div>
                    </div>
                </>
            </PlanDetails>
        </BorderedContainer>
    );
};

export default MembershipAvailablePlanCard;
