// General
import { ReactElement } from 'react';
import classNames from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import MembershipAvailablePlanCard from '../card';

// Interface and Types
import { MembershipAvailablePlanCardProps } from '../card';

// Styles
import './membership-available-plan-card-list.style.scss';

// Main component interface
export interface MembershipAvailablePlanCardListProps {
    planList: MembershipAvailablePlanCardProps[];
    className?: string;
}

// Main component
const MembershipAvailablePlanCardList = ({
    planList,
    className
}: MembershipAvailablePlanCardListProps): ReactElement => {
    const { t } = useTranslation();
    const containerClasses = classNames('membership-available-plan-card-list', className);

    return (
        <div className={containerClasses}>
            {planList &&
                planList
                    .filter((plan) => !plan.isPayAsYouGo)
                    .map((plan, index) => (
                        <MembershipAvailablePlanCard
                            key={`membership-available-plan-card-list-item-${index}`}
                            membershipPlanId={plan.membershipPlanId}
                            planName={plan.planName}
                            availableMembers={plan.availableMembers}
                            features={plan.features}
                            price={plan.price}
                            savePercentage={plan.savePercentage}
                            priceToJoin={plan.priceToJoin}
                            priceAccumulated={
                                plan.priceAccumulated?.total
                                    ? {
                                          total: plan.priceAccumulated?.total,
                                          monthlyFrequency: plan.priceAccumulated!.monthlyFrequency,
                                          showBilled: plan.priceAccumulated?.showBilled
                                      }
                                    : undefined
                            }
                            joinFee={plan.joinFee}
                            isSelected={plan.isSelected}
                            onChange={plan.onChange}
                        />
                    ))}

            {planList && planList.filter((plan) => plan.isPayAsYouGo).length > 0 && (
                <div className="card-divisor">
                    <span>{t('components.membership.payAsYouGo')}</span>
                </div>
            )}

            {planList &&
                planList
                    .filter((plan) => plan.isPayAsYouGo)
                    .map((plan, index) => (
                        <MembershipAvailablePlanCard
                            key={`membership-available-plan-card-list-item-${index}`}
                            membershipPlanId={plan.membershipPlanId}
                            planName={plan.planName}
                            availableMembers={plan.availableMembers}
                            features={plan.features}
                            price={plan.price}
                            priceToJoin={plan.priceToJoin}
                            priceAccumulated={
                                plan.priceAccumulated?.total
                                    ? {
                                          total: plan.priceAccumulated?.total,
                                          monthlyFrequency: plan.priceAccumulated!.monthlyFrequency,
                                          showBilled: plan.priceAccumulated?.showBilled
                                      }
                                    : undefined
                            }
                            joinFee={plan.joinFee}
                            isSelected={plan.isSelected}
                            isPayAsYouGo={plan.isPayAsYouGo}
                            onChange={plan.onChange}
                        />
                    ))}
        </div>
    );
};

export default MembershipAvailablePlanCardList;
