// General
import classNames from 'classnames';
import { ReactElement } from 'react';

import Button from 'ui-kit/button/button';
// Ui-kit
import ToastBox from 'ui-kit/toast-box/toast-box';

// Schemas
import { formatPrice } from 'schema/price.schema';

// Types
import { MembershipCartTotalsProps } from 'types/membership';

//Styles
import './membership-cart-totals.styles.scss';

const MembershipCartTotals = ({
    t,
    appliedDiscount,
    className,
    defaultFee,
    feeTotal,
    handleRemoveDiscount,
    hasInfoAlert,
    initialChargeAmount,
    isCarePlan,
    isOnDemandPlan,
    isPromoCodeValid,
    isSubmitting,
    isSuccessPage = false,
    membershipTotal,
    paymentModel,
    promoCode
}: MembershipCartTotalsProps): ReactElement => {
    const containerClasses = classNames('membership-cart-totals', className);
    const isValidPromo = promoCode && isPromoCodeValid && isSubmitting?.current?.errors?.promoCode === 'valid';
    const isValidSuccessPromo = promoCode && isSuccessPage;

    return (
        <div className={containerClasses}>
            <div className="membership-cart-totals__subTotal">
                {isCarePlan && !isSuccessPage && (
                    <div className="membership-cart-totals__subTotal-note">
                        {t('components.membershipCartTotals.title')}
                    </div>
                )}
                {hasInfoAlert && (
                    <div className="membership-cart-totals--info">
                        <ToastBox icon="default" variant="default">
                            {t('components.membershipCartTotals.infoAlert')}
                        </ToastBox>
                    </div>
                )}
                <div className="membership-cart-totals__items">
                    <div>
                        <div id="text">
                            {isCarePlan && t('components.membershipCartTotals.careToCarePlus')}
                            {isOnDemandPlan &&
                                paymentModel === 'Monthly' &&
                                t('components.membershipCartTotals.firstMonthPayment')}
                            {isOnDemandPlan &&
                                paymentModel === 'Annually' &&
                                t('components.membershipCartTotals.firstYearPayment')}
                        </div>
                        <div id="price">
                            {formatPrice(initialChargeAmount ? Number(initialChargeAmount) : Number(membershipTotal))}
                        </div>
                    </div>

                    <div>
                        <div id="text">
                            {(isCarePlan || isSuccessPage) && t('components.membershipCartTotals.membershipFee')}
                            {isOnDemandPlan &&
                                !isSuccessPage &&
                                t('components.membershipCartTotals.membershipFeeOnDemand')}
                        </div>
                        <div className="membership-cart-totals__items__joinFee">
                            <div>{formatPrice(defaultFee)}</div>
                            {(isCarePlan || isSuccessPage) && <div>{formatPrice(feeTotal)}</div>}
                        </div>
                    </div>

                    {(isValidPromo || isValidSuccessPromo) && (
                        <div className="membership-select-plan__sections__total__item">
                            <span id="text">{t('membership.selectPlan.discount', { promoCode })}</span>
                            <span>
                                -<strong>{formatPrice(appliedDiscount)}</strong>
                                {isValidPromo && (
                                    <Button
                                        className="remove-discount"
                                        variant="text"
                                        type="button"
                                        onClick={handleRemoveDiscount}
                                        dataGAFormName="remove-discount"
                                        label={t('membership.selectPlan.removeDiscount')}
                                    />
                                )}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className={`membership-cart-totals__total ${!isSuccessPage ? 'common-page' : ''}`}>
                <div>{t('components.membershipCartTotals.total')}</div>
                <div>
                    <div>{formatPrice(String(Number(membershipTotal) + Number(feeTotal)))}</div>
                </div>
            </div>
        </div>
    );
};

export default MembershipCartTotals;
